<footer class="text-lg-start">
        
  <div *ngIf="showTopFooter" class="top-footer">
    <ds-themed-text-section *ngIf="hasSiteFooterSections" [sectionId]="'site'" [textRowSection]=section [site]="(site | async)">      
    </ds-themed-text-section>      

    <div *ngIf="!hasSiteFooterSections" class=" container "> </div>    <!-- Origibal p-4 removed-->
  
  </div>


  <!-- Copyright -->
  <div class="bottom-footer d-flex justify-content-center align-items-left text-white p-2"> <!-- p-1 removed-->
    <div class="content-container">
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
          <a href="https://mylibrary.unimap.edu.my/index.php/2017-05-02-01-41-53/contact-us" alt="About Us"><b>About Us</b> </a>        
        </li>
        <li>        
          <a href="https://mylibrary.unimap.edu.my/index.php" alt="Contact Us" ><b>Contact Us</b> </a>        
        </li>
        <li>
          <a href="http://dspace.unimap.edu.my:80/xmlui/handle/123456789/75762" alt="Policies"><b>Policies</b> </a>        
        </li>
      </ul>
    </div>
  </div>
  <!-- Copyright -->

</footer>
